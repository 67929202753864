import type Client from '../client'
import type {
  ErrorAble,
  PermissionsRequest,
  PermissionsResponse,
} from '@/api'

export default class UserPermissions {
  constructor(private userId: number, private client: Client) {}

  sync(data: PermissionsRequest): Promise<ErrorAble<PermissionsResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.put(`user/${this.userId}/permissions`, { data }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }
}
