import { Module } from 'vuex'
import { suppliers as suppliersApi } from '@/api'

export const suppliers: Module<{}, {}> = {
  namespaced: true,

  actions: {
    async listing(_, page) {
      const response = await suppliersApi.go(page)

      return response
    },
    async find(_, id) {
      const response = await suppliersApi.find(id)

      return response.data
    },
    async create(_, data) {
      await suppliersApi.create(data)
    },
    async update(_, { id, ...data }) {
      await suppliersApi.update(id, data)
    },
  },
}
