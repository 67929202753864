import { RouteConfig } from 'vue-router'

const mergeConfigMeta = (
  options: RouteConfig,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  overrides: Record<string, any>
): RouteConfig => {
  return {
    ...options,
    meta: { ...(options.meta ?? {}), ...overrides },
  }
}

export const guest = (options: RouteConfig): RouteConfig => mergeConfigMeta(options, { requiresAuthentication: false })

export const auth = (options: RouteConfig): RouteConfig => mergeConfigMeta(options, { requiresAuthentication: true })
