export const randomString = (length = 24): string => {
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.split(
    '',
  )
  const response = []

  for (let i = 0; i < length; i++) {
    const index = Math.floor(Math.random() * charset.length)
    response.push(charset[index])
  }

  return response.join('')
}
