import { Module } from 'vuex'
import { pages as pagesApi } from '@/api'

export const pages: Module<{}, {}> = {
  namespaced: true,

  actions: {
    async listing(_, page) {
      const response = await pagesApi.go(page)

      return response
    },
    async find(_, id) {
      const response = await pagesApi.find(id)

      return response.data
    },
    async create(_, data) {
      await pagesApi.create(data)
    },
    async update(_, { id, ...data }) {
      await pagesApi.update(id, data)
    },
    async delete(_, id) {
      await pagesApi.delete(id)
    },
  },
}
