import type Client from '../client'
import PaginatedResponse from '../paginated-response'
import ProductVariation from './product-variation'
import ProductReview from './product-review'
import type {
  ErrorAble,
  ProductResponse,
  ProductParameters,
  ProductThumbnailResponse,
  RequestItemBag,
} from '@/api'
import ProductGallery from './product-gallery'

export default class Product {
  constructor(private client: Client) {}

  create(data: ProductParameters): Promise<ErrorAble<ProductResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post('product', { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  find(
    id: number,
    query: RequestItemBag = {},
  ): Promise<ErrorAble<ProductResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.get(`product/${id}`, { query }))
      } catch (error) {
        reject(error)
      }
    })
  }

  update(
    id: number,
    data: ProductParameters,
  ): Promise<ErrorAble<ProductResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post(`product/${id}`, { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  all(query: RequestItemBag = {}): Promise<PaginatedResponse<ProductResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          new PaginatedResponse(await this.client.get('product', { query })),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  allForSelect(): Promise<{id: number, name: string}[]> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.get('product/all'))
      } catch (error) {
        reject(error)
      }
    })
  }

  go(
    page: number,
    query: RequestItemBag = {},
  ): Promise<PaginatedResponse<ProductResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          new PaginatedResponse(
            await this.client.get('product', { query: { ...query, page } }),
          ),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  thumbnail(
    id: number,
    thumbnail: Blob,
    headers: RequestInit['headers'] = {},
  ): Promise<ProductThumbnailResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.post(`product/${id}/thumbnail`, {
            data: { thumbnail },
            headers,
          }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  get variations(): ProductVariation {
    return new ProductVariation(this.client)
  }

  get reviews(): ProductReview {
    return new ProductReview(this.client)
  }

  gallery(id: number): ProductGallery {
    return new ProductGallery(id, this.client)
  }
}
