<template>
  <img :src="src" v-bind="attrs" @click="$emit('click')"/>
</template>

<script>
export default {
  name: 'CImg',

  computed: {
    src() {
      let domain = ''

      switch (process.env.VUE_APP_IMAGE_PROVIDER) {
        case 'aws':
          domain = process.env.VUE_APP_IMAGE_AWS_BASEURL
          break
      }

      return `${domain}${this.$attrs.src}`
    },

    attrs() {
      const attrs = this.$attrs

      delete attrs.src

      return attrs
    },
  },
}
</script>
