import Client from '../client'
import type { ErrorAble } from '@/api'
import { RolesResponse } from '@/api'

export default class Role {
  constructor(private client: Client) {}
  all(): Promise<ErrorAble<RolesResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.get(`role`))
      } catch (error) {
        reject(error)
      }
    })
  }
}
