import { ResponseCollection } from './types'

export default class PaginatedResponse<T> {
  constructor(private collection: ResponseCollection<T>) {}

  public data(): T[] {
    return this.collection.data
  }

  public first(): string {
    return this.collection.links.first
  }

  public last(): string {
    return this.collection.links.last
  }

  public prev(): string {
    return this.collection.links.prev
  }

  public next(): string {
    return this.collection.links.next
  }

  public jump(page: number): string {
    if (page < 1 || page > this.collection.meta.last_page) {
      throw RangeError(
        `Cannot jump to page: ${page}. Range 1-${this.collection.meta.last_page}`,
      )
    }

    return `${this.collection.meta.path}?page=${page}`
  }

  get meta(): ResponseCollection<T>['meta'] {
    return this.collection.meta
  }
}
