import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './modules/auth'
import { products } from './modules/products'
import { categories } from './modules/categories'
import { suppliers } from './modules/suppliers'
import { toplists } from './modules/toplists'
import { orders } from './modules/orders'
import { pages } from './modules/pages'
import { base } from './modules/base'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    products,
    categories,
    suppliers,
    toplists,
    orders,
    pages,
    base,
  },
})

export default store
