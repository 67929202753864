import Client from '../client'
import PaginatedResponse from '../paginated-response'
import type {
  ErrorAble,
  AuthCredentials,
  UserResponse,
  RequestItemBag,
  UserParameters,
  MessageResponse,
  ForgotCredentials,
} from '@/api'
import UserPermissions from './user-permissions'

export default class User {
  constructor(private client: Client) {}

  login(data: AuthCredentials): Promise<ErrorAble<UserResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post('auth/login', { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  forgot(data: ForgotCredentials): Promise<ErrorAble<unknown>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post('auth/forgot', { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  logout(): Promise<unknown> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post('auth/logout'))
      } catch (error) {
        reject(error)
      }
    })
  }

  all(query: RequestItemBag = {}): Promise<PaginatedResponse<UserResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(new PaginatedResponse(await this.client.get('user', { query })))
      } catch (error) {
        reject(error)
      }
    })
  }

  find(
    id: number,
    query: RequestItemBag = {},
  ): Promise<ErrorAble<UserResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.get(`user/${id}`, { query }))
      } catch (error) {
        reject(error)
      }
    })
  }

  update(id: number, data: UserParameters): Promise<ErrorAble<UserResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.put(`user/${id}`, { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  refreshToken(): Promise<unknown> {
    return new Promise(async (resolve) => {
      const root = Client.options.base

      resolve(
        await fetch(`${root}/sanctum/csrf-cookie`, {
          mode: 'cors',
          credentials: 'include',
          referrerPolicy: 'no-referrer',
        }),
      )
    })
  }

  delete(id: number): Promise<ErrorAble<unknown>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.delete(`user/${id}`))
      } catch (error) {
        reject(error)
      }
    })
  }

  dataDownload(id: number): Promise<ErrorAble<MessageResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.get(`user/${id}/data-download`))
      } catch (error) {
        reject(error)
      }
    })
  }

  permissions(id: number): UserPermissions {
    return new UserPermissions(id, this.client)
  }

  impersonate(id: number): Promise<unknown> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post(`user/${id}/impersonate`))
      } catch (error) {
        reject(error)
      }
    })
  }

  verify(user: number): Promise<ErrorAble<unknown>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post(`user/${user}/verify`))
      } catch (error) {
        reject(error)
      }
    })
  }
}
