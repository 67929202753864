import type Client from '../client'
import PaginatedResponse from '../paginated-response'
import type {
  ErrorAble,
  RequestItemBag,
  SupplierContentParameters,
  SupplierContentResponse,
} from '@/api'

export default class CustomContent {
  constructor(private client: Client) {}

  create(
    id: number,
    data: SupplierContentParameters,
  ): Promise<ErrorAble<SupplierContentResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post(`supplier/${id}/content`, { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  update(
    id: number,
    data: SupplierContentParameters,
  ): Promise<ErrorAble<SupplierContentResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.put(`content/${id}`, { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  delete(id: number): Promise<ErrorAble<unknown>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.delete(`content/${id}`))
      } catch (error) {
        reject(error)
      }
    })
  }

  all(
    id: number,
    query: RequestItemBag = {},
  ): Promise<PaginatedResponse<SupplierContentResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          new PaginatedResponse(
            await this.client.get(`supplier/${id}/content`, { query }),
          ),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  go(
    id: number,
    page = 1,
    query: RequestItemBag = {},
  ): Promise<PaginatedResponse<SupplierContentResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          new PaginatedResponse(
            await this.client.get(`supplier/${id}/content`, {
              query: { ...query, page },
            }),
          ),
        )
      } catch (error) {
        reject(error)
      }
    })
  }
}
