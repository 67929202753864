import type Client from '../client'
import PaginatedResponse from '../paginated-response'
import CustomContent from './custom-content'
import SupplierImage from './supplier-image'
import SupplierGallery from './supplier-gallery'
import SupplierUser from './supplier-users'

import type {
  ErrorAble,
  SupplierResponse,
  SupplierParameters,
  SupplierInviteParameters,
  RequestItemBag,
} from '@/api'

export default class Supplier {
  constructor(private client: Client) {}

  create(data: SupplierParameters): Promise<ErrorAble<SupplierResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post('supplier', { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  update(
    id: number,
    data: SupplierParameters,
  ): Promise<ErrorAble<SupplierResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.put(`supplier/${id}`, { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  find(
    id: number,
    query: RequestItemBag = {},
  ): Promise<ErrorAble<SupplierResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.get(`supplier/${id}`, { query }))
      } catch (error) {
        reject(error)
      }
    })
  }

  all(
    query: RequestItemBag = {},
  ): Promise<PaginatedResponse<SupplierResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          new PaginatedResponse(await this.client.get('supplier', { query })),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

    invite(
        id: number,
        data: { invite_new: boolean, email: string },
    ): Promise<ErrorAble<unknown>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post(`supplier/${id}/invite`, { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  go(
    page: number,
    query: RequestItemBag = {},
  ): Promise<PaginatedResponse<SupplierResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          new PaginatedResponse(
            await this.client.get('supplier', { query: { ...query, page } }),
          ),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  // eslint-disable-next-line
  invoices(supplier_id: number | null, query: RequestItemBag = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.get('supplier/invoices', {
            query: { ...query, supplier_id },
          }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  get customContent(): CustomContent {
    return new CustomContent(this.client)
  }

  images(id: number): SupplierImage {
    return new SupplierImage(id, this.client)
  }

  gallery(id: number): SupplierGallery {
    return new SupplierGallery(id, this.client)
  }

  users(): SupplierUser {
    return new SupplierUser(this.client)
  }
}
