import type Client from '../client'
import type {
  ErrorAble,
  RequestItemBag,
  BaseResponse,
  DashboardResponse,
} from '@/api'

export default class Base {
  constructor(private client: Client) {}

  dashboard(): Promise<ErrorAble<DashboardResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.get('base/dashboard'))
      } catch (error) {
        reject(error)
      }
    })
  }

  all(query: RequestItemBag = {}): Promise<ErrorAble<BaseResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.get('base', { query }))
      } catch (error) {
        reject(error)
      }
    })
  }
}
