import type Client from '../client'
import PaginatedResponse from '../paginated-response'
import SupplierBatch from './supplier-batch'
import type {
  ErrorAble,
  ProductVariationParameters,
  ProductVariationResponse,
  RequestItemBag,
} from '@/api'

export default class ProductVariation {
  constructor(private client: Client) {}

  create(
    id: number,
    data: ProductVariationParameters,
  ): Promise<ErrorAble<ProductVariationResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.post(`product/${id}/product-variation`, { data }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  find(
    id: number,
    query: RequestItemBag = {},
  ): Promise<ErrorAble<ProductVariationResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.get(`product-variation/${id}`, {
            query,
          }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  update(
    id: number,
    data: ProductVariationParameters,
  ): Promise<ErrorAble<ProductVariationResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.put(`product-variation/${id}`, { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  all(
    id: number | null,
    query: RequestItemBag = {},
  ): Promise<PaginatedResponse<ProductVariationResponse>> {
    return new Promise(async (resolve, reject) => {
      const url = id ? `product/${id}/product-variation` : 'product-variation'

      try {
        resolve(new PaginatedResponse(await this.client.get(url, { query })))
      } catch (error) {
        reject(error)
      }
    })
  }

  go(
    id: number | null,
    page: number,
    query: RequestItemBag = {},
  ): Promise<PaginatedResponse<ProductVariationResponse>> {
    return new Promise(async (resolve, reject) => {
      const url = id ? `product/${id}/product-variation` : 'product-variation'

      try {
        resolve(
          new PaginatedResponse(
            await this.client.get(url, { query: { ...query, page } }),
          ),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  get batches(): SupplierBatch {
    return new SupplierBatch(this.client)
  }
}
