import { NavigationGuard } from 'vue-router'
import store from '../store'
import { cannot } from '../acl'
import { allowedRoles } from '../config/settings'

/**
 * This middleware checks to see if the current page is authenticated
 * or not. Since this is an administrative website, we will enforce
 * authentication by default and only explicitly whitelist pages that
 * do not require authentication, like the login page.
 */
export const checkAuthentication: NavigationGuard = (to, from, next) => {
  if (to.matched.some(record => 'requiresAuthentication' in record.meta)) {
    const authenticated = Boolean(store.getters['auth/authenticated'])

    if (!authenticated && to.name !== 'login' && to.name !== 'reset-password') {
      return next({ name: 'login' })
    }

    if (authenticated && to.meta!.requiresAuthentication) {
      const roles = store.getters['auth/user'].roles

      if (allowedRoles.some(r => roles.includes(r)) === false) {
        return next({ name: 'login' })
      }
    }

    if (authenticated && to.meta!.requiresAuthentication === false) {
      return next({ name: 'dashboard' })
    }
  }

  next()
}

export const checkACL: NavigationGuard = (to, from, next) => {
  if (to.matched.some(record => 'can' in record.meta)) {
    if (cannot(to.meta!.can)) {
      return next({ name: 'dashboard' })
    }
  }

  next()
}
