import type Client from '../client'
import type {
  ErrorAble,
  BookingFieldResponse,
  BookingFieldParameters,
} from '@/api'

export default class BookingField {
  constructor(private client: Client) {}

  create(
    template_id: number,
    data: BookingFieldParameters,
  ): Promise<ErrorAble<BookingFieldResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.post(`template/${template_id}/field`, { data }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  delete(field: number): Promise<ErrorAble<unknown>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.delete(`field/${field}`))
      } catch (error) {
        reject(error)
      }
    })
  }
}
