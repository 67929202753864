import { Module } from 'vuex'
import { orders as ordersApi } from '@/api'

export const orders: Module<{}, {}> = {
  namespaced: true,

  actions: {
    async listing(_, page) {
      const response = await ordersApi.go(page)

      return response
    },
    async find(_, id) {
      try {
        const response = await ordersApi.find(id)

        return response.data
      } catch (error) {
        console.log('find -> error', error)
      }
    },
  },
}
