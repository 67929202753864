export default [
  {
    text: 'Dashboards & Reports',
    isHeader: true,
  },
  {
    to: { name: 'salesTrend' },
    icon: 'fa fa-chart-line',
    text: 'Salestrend',
  },
  {
    to: { name: 'reports' },
    icon: 'fa fa-file-excel',
    text: 'Reports',
  },
  {
    text: 'Products',
    isHeader: true,
  },
  {
    to: { name: 'products' },
    icon: 'fa fa-box',
    text: 'Products',
  },
  {
    to: { name: 'orders' },
    icon: 'fa fa-shopping-cart',
    text: 'Orders',
  },
  {
    to: { name: 'invoices' },
    icon: 'fa fa-receipt',
    text: 'Invoices',
  },
  {
    to: { name: 'giftcards' },
    icon: 'fa fa-credit-card',
    text: 'Giftcards',
  },
  {
    to: { name: 'toplists' },
    icon: 'fa fa-stream',
    text: 'Toplists',
  },
  {
    to: { name: 'users' },
    icon: 'fa fa-users',
    text: 'Users',
  },
  {
    text: 'Suppliers & Bookings',
    isHeader: true,
  },
  {
    to: { name: 'suppliers' },
    icon: 'fa fa-people-carry',
    text: 'Suppliers',
  },
  {
    to: { name: 'csvcodes' },
    icon: 'fa fa-file-csv',
    text: 'CSV Codes',
  },
  {
    text: 'Locations',
    isHeader: true,
  },
  {
    to: { name: 'countries' },
    icon: 'fa fa-location-arrow',
    text: 'Countries',
  },
  {
    text: 'Misc',
    isHeader: true,
  },
  {
    to: { name: 'pages' },
    icon: 'fa fa-file-alt',
    text: 'Pages',
  },
  {
    to: { name: 'categories' },
    icon: 'fa fa-layer-group',
    text: 'Categories',
  },
]
