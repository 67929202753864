import type Client from '../client'
import type {
  ErrorAble,
  BookingTemplateResponse,
  BookingTemplateParameters,
} from '@/api'

export default class BookingTemplate {
  constructor(private client: Client) {}

  create(
    variation_id: number,
    data: BookingTemplateParameters,
  ): Promise<ErrorAble<BookingTemplateResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.post(`product-variation/${variation_id}/template`, {
            data,
          }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  update(
    id: number,
    data: BookingTemplateParameters,
  ): Promise<ErrorAble<BookingTemplateResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.put(`template/${id}`, { data }))
      } catch (error) {
        reject(error)
      }
    })
  }
}
