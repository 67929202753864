import type Client from '../client'
import PaginatedResponse from '../paginated-response'
import {
  CountryParametersWithImage,
  CountryResponse,
  ErrorAble,
  RequestItemBag,
} from '@/api'

export default class Country {
  constructor(private client: Client) {}

  create(
    data: CountryParametersWithImage,
  ): Promise<ErrorAble<CountryResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post('country', { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  update(
    id: number,
    data: CountryParametersWithImage,
  ): Promise<ErrorAble<CountryResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.put(`country/${id}`, { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  all(query: RequestItemBag = {}): Promise<PaginatedResponse<CountryResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          new PaginatedResponse(await this.client.get('country', { query })),
        )
      } catch (error) {
        reject(error)
      }
    })
  }
}
