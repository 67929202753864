import type Client from '../client'
import PaginatedResponse from '../paginated-response'
import type {
  ErrorAble,
  RequestItemBag,
  SupplierBookingCodeBatchParameters,
  SupplierBookingCodeBatchResponse,
} from '@/api'

export default class SupplierBatch {
  constructor(private client: Client) {}

  create(
    data: SupplierBookingCodeBatchParameters,
  ): Promise<ErrorAble<SupplierBookingCodeBatchResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.post(`product-variation/batch`, {
            data,
          }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  update(
        id: number,
        data: SupplierBookingCodeBatchParameters,
  ): Promise<ErrorAble<SupplierBookingCodeBatchResponse>> {
      return new Promise(async (resolve, reject) => {
          try {
              resolve(
                  await this.client.put(`product-variation/batch/${id}`, {
                      data,
                  }),
              )
          } catch (error) {
              reject(error)
          }
      })
  }

    destroy(
        id: number,
    ): Promise<ErrorAble<SupplierBookingCodeBatchResponse>> {
        return new Promise(async (resolve, reject) => {
            try {
                resolve(
                    await this.client.delete(`batch/${id}`),
                )
            } catch (error) {
                reject(error)
            }
        })
    }

  go(
    page: number,
    query: RequestItemBag = {},
  ): Promise<PaginatedResponse<SupplierBookingCodeBatchResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          new PaginatedResponse(
            await this.client.get('batch', {
              query: { ...query, page },
            }),
          ),
        )
      } catch (error) {
        reject(error)
      }
    })
  }
}
