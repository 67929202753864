import type Client from '../client'
import type {
  ErrorAble,
  SupplierImageParameters,
  SupplierImageResponse,
} from '@/api'

export default class SupplierImage {
  constructor(private supplierId: number, private client: Client) {}

  all(): Promise<ErrorAble<SupplierImageResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.get(`supplier/${this.supplierId}/images`))
      } catch (error) {
        reject(error)
      }
    })
  }

  update(
    id: number,
    data: SupplierImageParameters,
  ): Promise<ErrorAble<SupplierImageResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.put(`supplier/${this.supplierId}/images/${id}`, {
            data,
          }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  delete(id: number): Promise<ErrorAble<SupplierImageResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.delete(`supplier/${this.supplierId}/images/${id}`),
        )
      } catch (error) {
        reject(error)
      }
    })
  }
}
