import type Client from '../client'
import type { TagsResponse } from '@/api'
import { RequestItemBag } from '@/api'
import PaginatedResponse from '../paginated-response'

export default class Tags {
  constructor(private client: Client) {}

  all(query: RequestItemBag = {}): Promise<PaginatedResponse<TagsResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(new PaginatedResponse(await this.client.get('tags', { query })))
      } catch (error) {
        reject(error)
      }
    })
  }
}
