import type Client from '../client'
import type {
  DownloadResponse,
  ErrorAble,
  InvoiceDetailResponse,
  InvoiceResponse,
  RequestItemBag,
} from '@/api'
import PaginatedResponse from '../paginated-response'
import {InvoicePartialCreditRequest, InvoiceStatisticsResponse, InvoiceZipOrXlsxRequest} from "@/api";

export default class Invoice {
  constructor(private client: Client) {}

  generate(supplier_id: number): Promise<ErrorAble<InvoiceResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post(`invoice/${supplier_id}/generate`))
      } catch (error) {
        reject(error)
      }
    })
  }

  download(invoice_id: number): Promise<ErrorAble<DownloadResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post(`invoice/${invoice_id}/download`))
      } catch (error) {
        reject(error)
      }
    })
  }

  downloadZipOrXlsx(data: InvoiceZipOrXlsxRequest): Promise<ErrorAble<Blob>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post(`invoice/download`, { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  details(invoice: number): Promise<PaginatedResponse<InvoiceDetailResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          new PaginatedResponse(
            await this.client.get(`invoice/${invoice}/details`),
          ),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  markAsPaid(invoice: number): Promise<ErrorAble<InvoiceResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post(`invoice/${invoice}/paid`))
      } catch (error) {
        reject(error)
      }
    })
  }

  partialCredit(data: InvoicePartialCreditRequest): Promise<ErrorAble<InvoiceResponse>> {
    return new Promise(async (resolve, reject) => {
        try {
            resolve(await this.client.post(`invoice/partial_credit`, { data }))
        } catch (error) {
            reject(error)
        }
    })
  }

  go(
    page: number,
    query: RequestItemBag = {},
  ): Promise<PaginatedResponse<InvoiceResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          new PaginatedResponse(
            await this.client.get('invoice', {
              query: { ...query, page },
            }),
          ),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  statistics(): Promise<ErrorAble<InvoiceStatisticsResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.get(`invoice/statistics`))
      } catch (error) {
        reject(error)
      }
    })
  }
}
