import type Client from '../client'
import BookingDates from './booking-dates'
import BookingTemplate from './booking-template'
import BookingField from './booking-field'
import type { BookingParameters, ErrorAble, BookingResponse } from '@/api'

export default class Booking {
  constructor(private client: Client) {}

  create(data: BookingParameters): Promise<ErrorAble<BookingResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post('booking', { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  cancel(id: number, message: string, communicate: Array<string>): Promise<ErrorAble<unknown>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
            await this.client.delete(`booking/${id}`, { data: { message, communicate } })
        )
      } catch (error) {
        reject(error)
      }
  })
}

  get dates(): BookingDates {
    return new BookingDates(this.client)
  }

  get template(): BookingTemplate {
    return new BookingTemplate(this.client)
  }

  get field(): BookingField {
    return new BookingField(this.client)
  }

  resendVerificationEmail(
    code: number,
    role: string,
  ): Promise<ErrorAble<BookingResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.post(`booking/resend-confirmation`, {
            data: { code, role },
          }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }
}
