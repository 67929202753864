import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Client } from '@/api'
import { BootstrapVue } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import DateRangePicker from 'vue2-daterange-picker'
import VueInsProgressBar from 'vue-ins-progress-bar'
import wysiwyg from 'vue-wysiwyg'
import InstantSearch from 'vue-instantsearch'
import VueFroala from 'vue-froala-wysiwyg'
import CImg from './components/CImg.vue'

import { can, cannot } from './acl'
import './validation'

// VueStudio SCSS
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'vue-wysiwyg/dist/vueWysiwyg.css'
import './scss/styles.scss'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/js/plugins.pkgd.min.js'
// Vue config
Vue.config.errorHandler = function(err, vm) {
  if (err.message === 'Failed to fetch') {
    vm.$bvToast.toast(`There was an error processing your request.`, {
      title: 'Something went wrong.',
      variant: 'danger',
    })
  }
}

// Install BootstrapVue
Vue.use(BootstrapVue)
Vue.use(VueInsProgressBar, {
  position: 'fixed',
  show: true,
  height: '3px',
})
Vue.use(wysiwyg, { minHeight: '300px', maxHeight: '500px' })
Vue.use(InstantSearch)
Vue.use(VueFroala)

// Global components
Vue.component('apexchart', VueApexCharts)
Vue.component('date-range-picker', DateRangePicker)
Vue.component('c-img', CImg)

Vue.config.productionTip = false

if (process.env.VUE_APP_API_URL) {
  Client.setBase(process.env.VUE_APP_API_URL)
} else {
  Client.setBase(`https://${window.location.hostname}/api`)
}

store.dispatch('base/getBase')
store.dispatch('auth/attempt').then(() =>
  new Vue({
    router,
    store,
    render: h => h(App),
    provide: { $can: can, $cannot: cannot },
  }).$mount('#app')
)
