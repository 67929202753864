import type Client from '../client'
import PaginatedResponse from '../paginated-response'
import type {
  ErrorAble,
  PageParameters,
  PageResponse,
  RequestItemBag,
} from '@/api'

export default class Pages {
  constructor(private client: Client) {}

  create(data: PageParameters): Promise<ErrorAble<PageResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post('page', { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  find(
    id: number,
    query: RequestItemBag = {},
  ): Promise<ErrorAble<PageResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.get(`page/${id}`, { query }))
      } catch (error) {
        reject(error)
      }
    })
  }

  update(id: number, data: PageParameters): Promise<ErrorAble<PageResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.put(`page/${id}`, { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  delete(id: number): Promise<ErrorAble<unknown>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.delete(`page/${id}`))
      } catch (error) {
        reject(error)
      }
    })
  }

  go(
    page: number,
    query: RequestItemBag = {},
  ): Promise<PaginatedResponse<PageResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          new PaginatedResponse(
            await this.client.get('page', { query: { ...query, page } }),
          ),
        )
      } catch (error) {
        reject(error)
      }
    })
  }
}
