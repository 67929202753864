import type Client from '../client'
import {
  ErrorAble, NetSalesPerIntervalResponse,
} from '@/api'

export default class SalesTrend {
  constructor(private client: Client) {}

  netSalesPerInterval(date_from: string, date_to: string): Promise<ErrorAble<NetSalesPerIntervalResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.get('salestrend/net-sales-per-interval',{query: {date_from, date_to}}))
      } catch (error) {
        reject(error)
      }
    })
  }
}
