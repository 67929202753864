import type Client from '../client'
import PaginatedResponse from '../paginated-response'
import {
  CityParametersWithImage,
  CityResponse,
  ErrorAble,
  RequestItemBag,
} from '@/api'

export default class City {
  constructor(private client: Client) {}

  create(
    id: number,
    data: CityParametersWithImage,
  ): Promise<ErrorAble<CityResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post(`region/${id}/city`, { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  update(
    id: number,
    data: CityParametersWithImage,
  ): Promise<ErrorAble<CityResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.put(`city/${id}`, { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  paginated(query: RequestItemBag = {}): Promise<PaginatedResponse<CityResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(new PaginatedResponse(await this.client.get('city', { query })))
      } catch (error) {
        reject(error)
      }
    })
  }

  all(): Promise<ErrorAble<CityResponse[]>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.get(`city/all`))
      } catch (error) {
        reject(error)
      }
    })
  }
}
