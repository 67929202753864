import type Client from '../client'
import type { PasswordResetCredentials } from '@/api'

export default class Password {
  constructor(private client: Client) {}

  reset(email: string): Promise<unknown> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post('auth/forgot', { data: { email } }))
      } catch (error) {
        reject(error)
      }
    })
  }

  update(data: PasswordResetCredentials): Promise<unknown> {
    return new Promise(async (resolve, reject) => {
      if (data.password !== data.password_confirmation) {
        reject({ code: 'password.mismatch' })
        return
      }

      try {
        resolve(await this.client.post('auth/reset-password', { data }))
      } catch (error) {
        reject(error)
      }
    })
  }
}
