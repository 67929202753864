import type Client from '../client'
import PaginatedResponse from '../paginated-response'
import { PlaceResponse, RequestItemBag } from '@/api'

export default class Place {
  constructor(private client: Client) {}

  all(query: RequestItemBag = {}): Promise<PaginatedResponse<PlaceResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          new PaginatedResponse(await this.client.get('place', { query })),
        )
      } catch (error) {
        reject(error)
      }
    })
  }
}
