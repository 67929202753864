import { Module } from 'vuex'
import { categories as categoriesApi } from '@/api'

export const categories: Module<{}, {}> = {
  namespaced: true,

  actions: {
    async all() {
      const response = await categoriesApi.all()

      return response
    },
  },
}
