import store from './store'

/**
 * Can permissions
 * @param permission
 */
export const can = (permission: string) => {
  const { roles, permissions } = store.getters['auth/user']
  const superAdmin = roles?.some((role: string) => role === 'Super Admin')
  const validPermission = permissions?.some((perm: string) => perm === permission)

  return superAdmin ? superAdmin : validPermission
}

export const cannot = (permission: string) => !can(permission)
