import type Client from '../client'
import type {
  ErrorAble,
  GiftcardResponse,
  GiftcardCreateRequest,
  GiftcardUpdateRequest,
  RequestItemBag,
  GiftcardTransaction,
} from '@/api'
import PaginatedResponse from '../paginated-response'

export default class Giftcard {
  constructor(private client: Client) {}

  go(
    page: number,
    query: RequestItemBag = {},
  ): Promise<PaginatedResponse<GiftcardResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          new PaginatedResponse(
            await this.client.get('giftcard', {
              query: { ...query, page },
            }),
          ),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  add(data: GiftcardCreateRequest): Promise<ErrorAble<GiftcardResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post(`giftcard`, { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  update(
    id: number,
    data: GiftcardUpdateRequest & { order_detail_id?: number },
  ): Promise<ErrorAble<GiftcardResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.put(`giftcard/${id}`, { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  redeem(
    id: number,
    experienced_at: Date | null = null,
    supplier_id: number | null = null,
    variation_id: number | null = null,
  ): Promise<ErrorAble<unknown>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.post('giftcard/redeem', {
            data: { id, experienced_at, supplier_id, variation_id },
          }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  revertExchangedToOriginal(
    code: string,
    order_id: number,
  ): Promise<ErrorAble<unknown>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.post('giftcard/revertExchangedToOriginal', {
            data: { code, order_id },
          }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  exchange(
    codes: number[],
    email: string,
    combine = false,
  ): Promise<ErrorAble<GiftcardResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.post('giftcard/exchange', {
            data: { codes: codes, email: email, combine },
          }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  setNotUsed(ids: number[]): Promise<ErrorAble<GiftcardResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.post('giftcard/setNotUsed', {
            data: { ids },
          }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  combine(
    codes: number[],
    email: string,
  ): Promise<ErrorAble<GiftcardResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.post('giftcard/combine', {
            data: { codes: codes, email: email },
          }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  split(
    code: number,
    splitInto: number[],
  ): Promise<ErrorAble<GiftcardResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.post(`giftcard/${code}/split`, {
            data: { split: splitInto },
          }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  transactions(
    giftcard: number,
    page = 1,
    query: RequestItemBag = {},
  ): Promise<PaginatedResponse<GiftcardTransaction>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          new PaginatedResponse(
            await this.client.get(`giftcard/${giftcard}/transactions`, {
              query: { ...query, page },
            }),
          ),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  triggerEvent(
    giftcard: number,
    event: string,
  ): Promise<ErrorAble<GiftcardResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.post(`giftcard/${giftcard}/trigger-event`, {
            data: { event },
          }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  revertFromCart(giftcard: number): Promise<ErrorAble<GiftcardResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
            await this.client.post(`giftcard/${giftcard}/revertFromCart`),
        )
      } catch (error) {
        reject(error)
      }
    })
  }
}
