import type Client from '../client'
import PaginatedResponse from '../paginated-response'
import type {
  ErrorAble,
  ProductReviewParameters,
  ProductReviewResponse,
  ProductReviewReplyResponse,
  RequestItemBag,
} from '@/api'

export default class ProductReview {
  constructor(private client: Client) {}

  create(
    id: number | null,
    data: ProductReviewParameters,
  ): Promise<ErrorAble<ProductReviewResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.post(`product-variation/${id}/review`, { data }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  update(
    id: number,
    data: ProductReviewParameters,
  ): Promise<ErrorAble<ProductReviewResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.put(`review/${id}`, { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  delete(id: number): Promise<ErrorAble<unknown>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.delete(`review/${id}`))
      } catch (error) {
        reject(error)
      }
    })
  }

  approve(id: number): Promise<ErrorAble<ProductReviewResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post(`review/${id}/approve`))
      } catch (error) {
        reject(error)
      }
    })
  }

  reply(
    id: number,
    reply: string,
  ): Promise<ErrorAble<ProductReviewReplyResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.post(`review/${id}/reply`, { data: { reply } }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  all(
    id: number | null,
    query: RequestItemBag = {},
  ): Promise<PaginatedResponse<ProductReviewResponse>> {
    return new Promise(async (resolve, reject) => {
      const url = id ? `product-variation/${id}/review` : 'review'

      try {
        resolve(new PaginatedResponse(await this.client.get(url, { query })))
      } catch (error) {
        reject(error)
      }
    })
  }

  go(
    id: number | null,
    page: number,
    query: RequestItemBag = {},
  ): Promise<PaginatedResponse<ProductReviewResponse>> {
    return new Promise(async (resolve, reject) => {
      const url = id ? `product-variation/${id}/review` : 'review'

      try {
        resolve(
          new PaginatedResponse(
            await this.client.get(url, { query: { ...query, page } }),
          ),
        )
      } catch (error) {
        reject(error)
      }
    })
  }
}
