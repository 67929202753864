import Vue from 'vue'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, required_if, email, alpha, length, confirmed, integer, min_value } from 'vee-validate/dist/rules'

Vue.component('validation-observer', ValidationObserver)
Vue.component('validation-provider', ValidationProvider)

// Validation rules
extend('required', required)
extend('required_if', required_if)
extend('email', email)
extend('alpha', alpha)
extend('length', length)
extend('confirmed', confirmed)
extend('integer', integer)
extend('min_value', min_value)
