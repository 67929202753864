import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { checkAuthentication, checkACL } from './middlewares'
import { auth, guest } from './decorators'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  auth({
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../pages/Dashboard'),
  }),
  auth({
    path: '/dashboard/analytics',
    name: 'analytics',
    component: () => import(/* webpackChunkName: "analytics" */ '../pages/AnalyticsPage.vue'),
  }),
  auth({
    path: '/dashboard/products',
    name: 'products',
    component: () => import(/* webpackChunkName: "products" */ '../pages/Products'),
  }),
  auth({
    path: '/dashboard/products/new',
    name: 'products.new',
    meta: { can: 'product.create' },
    component: () => import(/* webpackChunkName: "products-form" */ '../pages/Products/ProductsFormPage.vue'),
    children: [
      auth({
        path: '/dashboard/products/:id/edit',
        name: 'products.edit',
        children: [
          auth({
            path: 'variations',
            name: 'products.edit.variations',
            children: [
              {
                path: 'new',
                name: 'product.variations.new',
                meta: { can: 'product.create' },
              },
              {
                path: ':variation/edit',
                name: 'product.variations.edit',
                meta: { can: 'product.create' },
              },
            ],
          }),
          auth({
            path: 'images',
            name: 'products.edit.images',
          }),
          auth({
            path: 'reviews',
            name: 'products.edit.reviews',
          }),
        ],
      }),
    ],
  }),
  auth({
    path: '/dashboard/products/:id/variations/new',
    name: 'variations.new',
    meta: { can: 'product.create' },
    component: () => import(/* webpackChunkName: "variation-form" */ '../pages/Products/ProductsVariationForm.vue'),
    children: [
      {
        path: '/dashboard/product-variations/:id/edit',
        name: 'variations.edit',
        meta: { can: 'product.create' },
        component: () => import(/* webpackChunkName: "variation-form" */ '../pages/Products/ProductsVariationForm.vue'),
      },
    ],
  }),
  auth({
    path: '/dashboard/orders',
    component: () => import(/* webpackChunkName: "orders" */ '../pages/OrdersPage/OrdersWrapper.vue'),
    children: [
      {
        path: '/',
        name: 'orders',
        component: () => import(/* webpackChunkName: "orders" */ '../pages/OrdersPage'),
      },
      {
        path: 'to-pack',
        name: 'orders.to-pack',
        component: () => import(/* webpackChunkName: "orders" */ '../pages/OrdersPage/OrdersToPack.vue'),
      },
    ],
  }),
  auth({
    path: '/dashboard/pending-reviews',
    name: 'pending-reviews',
    component: () => import(/* webpackChunkName: "orders" */ '../pages/ReviewsPage.vue'),
  }),
  auth({
    path: '/dashboard/toplists',
    name: 'toplists',
    component: () => import(/* webpackChunkName: "toplists" */ '../pages/ToplistsPage.vue'),
  }),
  auth({
    path: '/dashboard/toplists/new',
    name: 'toplists.new',
    meta: { can: 'toplist.manage' },
    component: () => import(/* webpackChunkName: "toplists-form" */ '../pages/ToplistsFormPage.vue'),
    children: [
      auth({
        path: '/dashboard/toplists/:id/edit',
        name: 'toplists.edit',
        meta: { can: 'toplist.manage' },
      }),
    ],
  }),
  auth({
    path: '/dashboard/suppliers',
    name: 'suppliers',
    component: () => import(/* webpackChunkName: "suppliers" */ '../pages/SuppliersPage.vue'),
  }),
  auth({
    path: '/dashboard/csvcodes',
    name: 'csvcodes',
    component: () => import(/* webpackChunkName: "csvcodes" */ '../pages/CSVCodes'),
  }),
  auth({
    path: '/dashboard/suppliers/:id/gallery',
    name: 'supplier.gallery',
    meta: { can: 'supplier.manage' },
    component: () => import(/* webpackChunkName: "supplier-gallery" */ '../pages/SupplierGallery'),
  }),
  auth({
    path: '/dashboard/suppliers/:id/gallery-images',
    name: 'supplier.gallery.images',
    meta: { can: 'supplier.manage' },
    component: () => import(/* webpackChunkName: "supplier-gallery" */ '../pages/SupplierGallery'),
  }),
  auth({
    path: '/dashboard/suppliers/:id/content',
    name: 'supplier.gallery.content',
    meta: { can: 'supplier.manage' },
    component: () => import(/* webpackChunkName: "supplier-gallery" */ '../pages/SupplierGallery'),
  }),
  auth({
    path: '/dashboard/suppliers/new',
    name: 'suppliers.new',
    meta: { can: 'supplier.manage' },
    component: () => import(/* webpackChunkName: "suppliers-form" */ '../pages/SuppliersFormPage.vue'),
    children: [
      auth({
        path: '/dashboard/suppliers/:id/edit',
        name: 'suppliers.edit',
        meta: { can: 'supplier.manage' },
      }),
      auth({
        path: '/dashboard/suppliers/:id/users',
        name: 'suppliers.users',
        meta: { can: 'supplier.manage' },
      }),
    ],
  }),
  auth({
    path: '/dashboard/suppliers/:id/bookings',
    name: 'suppliers.bookings',
    component: () => import(/* webpackChunkName: "suppliers-bookings" */ '../pages/SuppliersBookingSettings.vue'),
  }),
  auth({
    path: '/dashboard/countries',
    name: 'countries',
    component: () => import(/* webpackChunkName: "countries" */ '../pages/Countries'),
  }),
  auth({
    path: '/dashboard/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '../pages/Users'),
  }),
  auth({
    path: '/dashboard/users/:id',
    component: () => import('../pages/Users/UserDetailsLayout.vue'),
    children: [
      {
        path: 'profile',
        name: 'users.profile',
        component: () => import('../pages/Users/UserProfile.vue'),
      },
      {
        path: 'settings',
        name: 'users.settings',
        component: () => import('../pages/Users/UserSettings.vue'),
      },
    ],
  }),
  auth({
    path: '/dashboard/pages',
    name: 'pages',
    component: () => import(/* webpackChunkName: "pages" */ '../pages/Pages'),
  }),
  auth({
    path: '/dashboard/pages/new',
    name: 'pages.new',
    meta: { can: 'pages.create' },
    component: () => import(/* webpackChunkName: "pages" */ '../pages/Pages/PagesFormPage.vue'),
    children: [
      auth({
        path: '/dashboard/pages/:id/edit',
        name: 'pages.edit',
      }),
    ],
  }),
  auth({
    path: '/dashboard/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "search" */ '../pages/SearchPage'),
  }),
  auth({
    path: '/dashboard/faqs',
    name: 'faqs',
    component: () => import(/* webpackChunkName: "faqs" */ '../pages/FAQPage.vue'),
  }),
  auth({
    path: '/dashboard/categories',
    name: 'categories',
    component: () => import(/* webpackChunkName: "categories" */ '../pages/Categories'),
  }),
  auth({
    path: '/dashboard/categories/new',
    name: 'categories.new',
    component: () => import(/* webpackChunkName: "categories" */ '../pages/Categories/CategoriesFormPage.vue'),
  }),
  auth({
    path: '/dashboard/categories/:slug/edit',
    name: 'categories.edit',
    component: () => import(/* webpackChunkName: "categories" */ '../pages/Categories/CategoriesFormPage.vue'),
  }),
  auth({
    path: '/dashboard/invoices',
    name: 'invoices',
    component: () => import(/* webpackChunkName: "invoices" */ '../pages/Invoices'),
  }),
  auth({
    path: '/dashboard/giftcards',
    name: 'giftcards',
    component: () => import(/* webpackChunkName: "giftcards" */ '../pages/GiftcardPage.vue'),
  }),
  auth({
    path: '/dashboard/salestrend',
    name: 'salesTrend',
    component: () => import(/* webpackChunkName: "dashboard" */ '../pages/SalesTrendPage.vue'),
  }),
  auth({
    path: '/dashboard/reports',
    name: 'reports',
    component: () => import(/* webpackChunkName: "dashboard" */ '../pages/ReportsPage.vue'),
  }),
  guest({
    path: '/dashboard/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../pages/LoginPage.vue'),
  }),
  guest({
    path: '/dashboard/reset-password',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "login" */ '../pages/ResetPasswordPage.vue'),
  }),
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
})

router.beforeEach(checkAuthentication)
router.beforeEach(checkACL)

export default router
