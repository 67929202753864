import type Client from '../client'
import Country from './countries'
import Region from './regions'
import City from './cities'

export default class Location {
  constructor(private client: Client) {}

  get countries(): Country {
    return new Country(this.client)
  }

  get regions(): Region {
    return new Region(this.client)
  }

  get cities(): City {
    return new City(this.client)
  }
}
