import { Module } from 'vuex'
import * as Cookies from 'js-cookie'
import {
  account,
  Client,
  users,
  AuthCredentials,
  ForgotCredentials,
  passwords,
  PasswordResetCredentials,
} from '@/api'
import { AuthState } from './module-types'

export const auth: Module<AuthState, {}> = {
  namespaced: true,

  state: { user: {} },

  getters: {
    authenticated(state) {
      return state.user.id && state.user.email
    },

    user(state) {
      return state.user
    },
  },

  mutations: {
    SET_USER(state, user: AuthState['user']) {
      state.user = user
    },
  },

  actions: {
    async attempt({ dispatch }) {
      await dispatch('refreshToken')
      await dispatch('me')
    },

    async refreshToken() {
      await users.refreshToken()

      const xsrfToken = Cookies.get('XSRF-TOKEN') as string

      Client.setXSRFToken(xsrfToken)
    },

    async me({ commit }) {
      try {
        const user = await account.show()

        commit('SET_USER', user.data)
      } catch (error) {
        return error
      }
    },

    async login({ commit }, credentials: AuthCredentials) {
      try {
        const response = await users.login(credentials)

        commit('SET_USER', response.data)
      } catch (error) {
        return error
      }
    },

    async forgot(ActionContext, forgot: ForgotCredentials) {
      return await users.forgot(forgot)
    },

    async resetPassword(ActionContext, reset: PasswordResetCredentials) {
      return await passwords.update(reset)
    },

    async logout() {
      await users.logout()
    },
  },
}
