import type Client from '../client'
import type {
  ErrorAble,
  BookingDateParameters,
  BookingDateResponse,
  BookingDateExportResponse,
  BookingDateExportParameters,
  BookingResponse,
  RequestItemBag,
} from '@/api'
import PaginatedResponse from '../paginated-response'

export default class BookingDates {
  constructor(private client: Client) {}

  all(
    query: RequestItemBag = {},
  ): Promise<PaginatedResponse<BookingDateResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          new PaginatedResponse(
            await this.client.get('booking-dates', { query }),
          ),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  create(
    id: number,
    data: BookingDateParameters,
  ): Promise<ErrorAble<BookingDateResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.post(`product-variation/${id}/date`, { data }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  update(
    id: number,
    data: BookingDateParameters,
  ): Promise<ErrorAble<BookingDateResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.put(`date/${id}`, { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  cancel(id: number, message: string, communicate: Array<string>): Promise<ErrorAble<unknown>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.post(`date/${id}/cancel`, { data: { message, communicate } }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  export(
    id: number,
    format: BookingDateExportParameters['format'] = 'csv',
  ): Promise<ErrorAble<BookingDateExportResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.post(`date/${id}/export`, { data: { format } }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  bookings(id: number): Promise<PaginatedResponse<BookingResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          new PaginatedResponse(await this.client.get(`date/${id}/booking`)),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  verifyGiftcard(id: number, code: number): Promise<ErrorAble<unknown>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.post(`date/${id}/giftcard`, { data: { code } }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }
}
