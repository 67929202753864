import type Client from '../client'
import PaginatedResponse from '../paginated-response'

import type { ErrorAble, SupplierResponse, RequestItemBag } from '@/api'

export default class SupplierUser {
  constructor(private client: Client) {}

  add(supplier: number, user_id: number): Promise<ErrorAble<SupplierResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.post(`supplier/${supplier}/supplier-user`, {
            data: { user_id },
          }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  remove(id: number): Promise<ErrorAble<unknown>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.delete(`supplier-user/${id}`))
      } catch (error) {
        reject(error)
      }
    })
  }

  all(
    supplier: number,
    query: RequestItemBag = {},
  ): Promise<PaginatedResponse<SupplierResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          new PaginatedResponse(
            await this.client.get(`supplier/${supplier}/supplier-user`, {
              query,
            }),
          ),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  invites(
    supplier: number,
    query: RequestItemBag = {},
  ): Promise<PaginatedResponse<SupplierResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          new PaginatedResponse(
            await this.client.get(
              `supplier/${supplier}/supplier-user/invites`,
              {
                query,
              },
            ),
          ),
        )
      } catch (error) {
        reject(error)
      }
    })
  }
}
