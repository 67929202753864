import type Client from '../client'
import type { OrderResponse, RequestItemBag } from '@/api'
import PaginatedResponse from '../paginated-response'

export default class OrderDetails {
  constructor(private client: Client) {}

  all(
    order: number,
    query: RequestItemBag = {},
  ): Promise<PaginatedResponse<OrderResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          new PaginatedResponse(
            await this.client.get(`order/${order}/order-detail`, { query }),
          ),
        )
      } catch (error) {
        reject(error)
      }
    })
  }
}
