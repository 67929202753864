import type Client from '../client'
import {
  ErrorAble,
} from '@/api'

export default class Report {
  constructor(private client: Client) {}

  download(type:string, date_from: string, date_to: string): Promise<ErrorAble<Blob>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.get('report/download',{query: {type, date_from, date_to}}))
      } catch (error) {
        reject(error)
      }
    })
  }
}
