<template>
  <div id="footer" class="app-footer">
    &copy; 2020 YouWish AS. All rights reserved.
  </div>
</template>

<script>
export default {
  name: 'FooterComponent',
}
</script>
