import type Client from '../client'
import {
  RegionResponse,
  ErrorAble,
  RegionParametersWithImage,
} from '@/api'

export default class Region {
  constructor(private client: Client) {}

  create(
    id: number,
    data: RegionParametersWithImage,
  ): Promise<ErrorAble<RegionResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post(`country/${id}/region`, { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  update(
    id: number,
    data: RegionParametersWithImage,
  ): Promise<ErrorAble<RegionResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.put(`region/${id}`, { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  image(
    id: number,
    data: RegionParametersWithImage,
  ): Promise<ErrorAble<RegionResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post(`region/${id}/image`, { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  all(): Promise<ErrorAble<RegionResponse[]>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.get(`region/all`))
      } catch (error) {
        reject(error)
      }
    })
  }
}
