<template>
  <div class="menu-search">
    <div class="menu-search-icon">
      <i class="fa fa-search" />
    </div>

    <div class="menu-search-input">
      <input v-model="query" type="search" class="form-control" placeholder="Search..." @input="parseQuery" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlobalSearch',
  data() {
    return { query: '' }
  },
  methods: {
    parseQuery() {
      if (this.query.length > 2) {
        return this.$router.push({
          name: 'search',
          query: { query: this.query },
        })
      }
    },
  },
}
</script>
