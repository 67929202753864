import Client from '../client'
import type { ErrorAble, UserResponse, UserParameters } from '@/api'

export default class Account {
  constructor(private client: Client) {}

  show(): Promise<ErrorAble<UserResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.get('account/me'))
      } catch (error) {
        reject(error)
      }
    })
  }

  update(data: UserParameters): Promise<ErrorAble<UserResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.put(`account/me`, { data }))
      } catch (error) {
        reject(error)
      }
    })
  }
}
