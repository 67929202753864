import type Client from '../client'
import type {
  ErrorAble,
  OrderResponse,
  RefundRequest,
  RequestItemBag,
} from '@/api'
import PaginatedResponse from '../paginated-response'
import OrderDetails from './order-details'

export default class Order {
  constructor(private client: Client) {}

  find(
    id: number,
    query: RequestItemBag = {},
  ): Promise<ErrorAble<OrderResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.get(`orders/${id}`, { query }))
      } catch (error) {
        reject(error)
      }
    })
  }

  all(query: RequestItemBag = {}): Promise<PaginatedResponse<OrderResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          new PaginatedResponse(await this.client.get('orders', { query })),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  update(
    order_id: number,
    first_name: string,
    last_name: string,
    phone: string,
    email: string,
    address: string,
    zip: string,
    city: string,
  ): Promise<ErrorAble<OrderResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.put(`orders/${order_id}`, {
            data: {
              first_name,
              last_name,
              phone,
              email,
              address,
              zip,
              city,
            },
          }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  markAsPacked(ids: number[]): Promise<ErrorAble<unknown>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.post(`orders/packed`, {
            query: { orders: ids.join(',') },
          }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  markAsPaid(id: number): Promise<ErrorAble<OrderResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post(`order/${id}/markAsPaid`))
      } catch (error) {
        reject(error)
      }
    })
  }

  resendVouchers(id: number): Promise<ErrorAble<OrderResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post(`order/${id}/resendVouchers`))
      } catch (error) {
        reject(error)
      }
    })
  }

  resendReciept(id: number): Promise<ErrorAble<OrderResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post(`order/${id}/resendReciept`))
      } catch (error) {
        reject(error)
      }
    })
  }

  regenerateVouchers(id: number): Promise<ErrorAble<OrderResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post(`order/${id}/regenerate`))
      } catch (error) {
        reject(error)
      }
    })
  }

  go(
    page: number,
    query: RequestItemBag = {},
  ): Promise<PaginatedResponse<OrderResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          new PaginatedResponse(
            await this.client.get('order', { query: { ...query, page } }),
          ),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  get details(): OrderDetails {
    return new OrderDetails(this.client)
  }

  addGiftcard(
    id: number,
    giftcard_id: number,
    sum: number,
  ): Promise<ErrorAble<unknown>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.post(`order/${id}/addGiftcard`, {
            data: { giftcard_id, sum },
          }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  addComment(id: number, comment: string): Promise<ErrorAble<unknown>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.post(`order/${id}/comment`, { data: { comment } }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  refund(id: number, data?: RefundRequest): Promise<ErrorAble<OrderResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post(`refund/${id}`, { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  revertToGiftcard(id: number): Promise<ErrorAble<OrderResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post(`order/${id}/revertToGiftcard`))
      } catch (error) {
        reject(error)
      }
    })
  }
}
