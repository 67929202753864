import type Client from '../client'
import PaginatedResponse from '../paginated-response'
import {
  ErrorAble,
  CategoryResponse,
  RequestItemBag,
  CategoryParametersWithImage,
} from '@/api'

export default class Category {
  constructor(private client: Client) {}

  create(
    data: CategoryParametersWithImage,
  ): Promise<ErrorAble<CategoryResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post('category', { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  update(
    id: number,
    data: CategoryParametersWithImage,
  ): Promise<ErrorAble<CategoryResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.put(`category/${id}`, { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  findBySlug(
    slug: string,
    query: RequestItemBag = {},
  ): Promise<ErrorAble<CategoryResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.get(`category/${slug}`, { query }))
      } catch (error) {
        reject(error)
      }
    })
  }

  all(
    query: RequestItemBag = {},
  ): Promise<PaginatedResponse<CategoryResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          new PaginatedResponse(await this.client.get('category', { query })),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  go(
    page: number,
    query: RequestItemBag = {},
  ): Promise<PaginatedResponse<CategoryResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          new PaginatedResponse(
            await this.client.get('category', { query: { ...query, page } }),
          ),
        )
      } catch (error) {
        reject(error)
      }
    })
  }
}
