import { Module } from 'vuex'
import { BaseState } from './module-types'
import { base as baseApi } from '@/api'

export const base: Module<BaseState, {}> = {
  namespaced: true,

  state: {
    categories: [],
    places: [],
  },

  getters: {
    categories(state) {
      return state.categories
    },

    places(state) {
      return state.places
    },
  },

  mutations: {
    SET_BASE: (state, data) => {
      state.categories = data.categories
      state.places = data.places
    },
  },

  actions: {
    async getBase({ commit }) {
      const res = await baseApi.all()
      commit('SET_BASE', res.data)
    },
  },
}
