import type Client from '../client'
import PaginatedResponse from '../paginated-response'
import type {
  ErrorAble,
  ToplistParameters,
  ToplistResponse,
  RequestItemBag,
} from '@/api'

export default class Toplists {
  constructor(private client: Client) {}

  create(data: ToplistParameters): Promise<ErrorAble<ToplistResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.post('toplist', { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  find(
    id: number,
    query: RequestItemBag = {},
  ): Promise<ErrorAble<ToplistResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.get(`toplist/${id}`, { query }))
      } catch (error) {
        reject(error)
      }
    })
  }

  update(
    id: number,
    data: ToplistParameters,
  ): Promise<ErrorAble<ToplistResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.put(`toplist/${id}`, { data }))
      } catch (error) {
        reject(error)
      }
    })
  }

  all(query: RequestItemBag = {}): Promise<PaginatedResponse<ToplistResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          new PaginatedResponse(await this.client.get('toplist', { query })),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  go(
    page: number,
    query: RequestItemBag = {},
  ): Promise<PaginatedResponse<ToplistResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          new PaginatedResponse(
            await this.client.get('toplist', { query: { ...query, page } }),
          ),
        )
      } catch (error) {
        reject(error)
      }
    })
  }
}
