import { Module } from 'vuex'
import { toplists as toplistsApi } from '@/api'

export const toplists: Module<{}, {}> = {
  namespaced: true,

  actions: {
    async listing(_, page) {
      const response = await toplistsApi.go(page)

      return response
    },
    async find(_, id) {
      const response = await toplistsApi.find(id)

      return response.data
    },
    async create(_, data) {
      return await toplistsApi.create(data)
    },
    async update(_, { id, ...data }) {
      await toplistsApi.update(id, data)
    },
  },
}
