export default {
  appBoxedLayout: false,
  appSidebarMinified: false,
  appSidebarMobileToggled: false,
  appSidebarMobileClosed: false,
  appContentFullWidth: false,
  appContentFullHeight: false,
  appWithFooter: false,
  appWithoutSidebar: false,
  appWithoutHeader: false,
}
