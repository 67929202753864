import * as qs from 'qs'
import { randomString } from './helpers'
import type { ClientOptions, RequestItemBag, ErrorCode } from './types'

export type RequestOptions = {
  query?: RequestItemBag
  data?: RequestItemBag
  headers?: RequestInit['headers']
}

const stringifyQuery = (query: RequestOptions['query']): string => {
  const stringified = qs.stringify(query)

  return stringified ? `?${stringified}` : ''
}

// eslint-disable-next-line
const isBlob = (item: any) => item instanceof Blob || item instanceof File

const isString = (item: any) => typeof item === 'string' || item instanceof String

export default class Client {
  public static options: ClientOptions = {
    base: 'https://youwish.no/api',
    token: '',
    mode: 'production',
    uuid: '',
    xsrfToken: '',
  }

  public static setBase(base: ClientOptions['base']): void {
    Client.options.base = base
  }

  public static setToken(token: ClientOptions['token']): void {
    Client.options.token = token
  }

  public static setMode(mode: 'testing'): void {
    Client.options.mode = mode
  }

  public static setGuest(uuid: string): void {
    Client.options.uuid = uuid
  }

  public static setXSRFToken(token: string): void {
    Client.options.xsrfToken = token
  }

  public get<T>(path: string, options?: RequestOptions): Promise<T> {
    return this.send<T>(path, options as RequestOptions, { method: 'GET', body: null })
  }

  public post<T>(path: string, options?: RequestOptions): Promise<T> {
    return this.send<T>(path, options as RequestOptions, { method: 'POST' })
  }

  public put<T>(path: string, options?: RequestOptions): Promise<T> {
    return this.send<T>(path, options as RequestOptions, { method: 'PUT' })
  }

  public delete<T>(path: string, options?: RequestOptions): Promise<T> {
    return this.send<T>(path, options as RequestOptions, { method: 'DELETE' })
  }

  public static guestId(): string {
    return `guest-${randomString()}`
  }

  /** send */
  private send<T>(
    path: string,
    options: RequestOptions,
    init?: RequestInit,
  ): Promise<T> {
    const query = stringifyQuery(options?.query ?? {})

    return new Promise(async (resolve, reject) => {
      const url = `${Client.options.base}/${path}${query}`
      const data = options?.data ?? {}
      const body = this.generateBody(data)

      if (init?.method === 'PUT' && body instanceof FormData) {
        body.append('_method', 'PUT')
        init.method = 'POST'
      }

      try {
        this.debug('Youwish options:', Client.options)
        this.debug('Youwish Client:', 'calling', url, 'with', body)
        this.debug('Youwish Headers:', this.generateHeaders(data))

        const response = await fetch(url, {
          body,
          ...init,
          mode: 'cors',
          referrerPolicy: 'origin',
          credentials: 'include',
          headers: {
            ...(options?.headers ?? {}),
            ...this.generateHeaders(data),
          },
        } as any)

        if (response.ok) {
          const contentType = response.headers.get('content-type')
          if (contentType && contentType.indexOf('application/json') !== -1) {
            resolve(await response.json())
          } else if (contentType && (
            contentType.indexOf('application/zip') !== -1 ||
            contentType.indexOf('text/csv') !== -1 ||
            contentType.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') !== -1
          )) {
            resolve(await response.blob() as unknown as Promise<T>)
          } else {
            resolve()
          }
        } else {
          reject({
            code: this.normalizeErrorCode(response),
            ...(await response.json()),
          })
        }
      } catch (error) {
        reject(error)
      }
    })
  }

  private valueForFormData = (value: any) => isBlob(value) || isString(value) ? value : JSON.stringify(value)

  private generateBody(content: RequestItemBag): string | FormData {
    if (!this.containsBlob(content)) {
      return JSON.stringify(content)
    }

    const data = new FormData()

    Object.keys(content).forEach((key) => {
      if (Array.isArray(content[key])) {
        // eslint-disable-next-line
        return content[key].forEach((item: any, index: number) =>
            data.append(`${key}[${index}]`, this.valueForFormData(item))
        )
      }

      data.append(key, this.valueForFormData(content[key]))
    })

    return data
  }

  private containsBlob(content: RequestItemBag): boolean {
    return Object.keys(content).some((key: string) =>
      Array.isArray(content[key])
        ? content[key].some((item: any) => isBlob(item)) // eslint-disable-line
        : isBlob(content[key]),
    )
  }

  private generateHeaders(content: RequestItemBag) {
    return {
      ...this.authAndGuestToken(),
      ...(this.containsBlob(content)
        ? {}
        : { 'Content-Type': 'application/json' }),
      Accept: 'application/json',
      Referer: Client.options.base?.replace('/api', ''),
      Origin: Client.options.base?.replace('/api', ''),
    }
  }

  private authAndGuestToken() {
    return {
      ...(Client.options?.token
        ? { Authorization: `Bearer ${Client.options.token}` }
        : {}),
      ...(Client.options?.xsrfToken
        ? { 'X-XSRF-TOKEN': Client.options.xsrfToken }
        : {}),
      ...(Client.options?.uuid
        ? { 'X-Youwish-Global-Id': Client.options.uuid }
        : {}),
    }
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  private debug(...params: any[]): void {
    if (Client.options.mode !== 'testing') {
      this.log(...params)
    }
  }

  private log(...params: any[]): void {
    const description: string[] = []

    params.forEach((param: any) => {
      if (typeof param === 'object') {
        description.push(JSON.stringify(param))
      }

      description.push(String(param))
    })

    console.debug(...description)
  }

  private normalizeErrorCode(response: Response): string | undefined {
    const map = new Map<number, ErrorCode>()
    map.set(401, 'request.unauthorized')
    map.set(403, 'request.forbidden')
    map.set(404, 'resource.missing')
    map.set(422, 'validation.failed')

    if (!map.has(response.status)) {
      response.text().then((content) => this.log('response:', content))
      throw Error(`Unhandled error response code: ${response.status}`)
    }

    return map.get(response.status)
  }
}
