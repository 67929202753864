import type Client from '../client'
import type {
  ErrorAble,
  ProductGalleryRequest,
  ProductGalleryResponseData,
} from '@/api'

export default class ProductGallery {
  constructor(private productId: number, private client: Client) {}

  addImage(
    data: ProductGalleryRequest,
    headers: RequestInit['headers'] = {},
  ): Promise<ErrorAble<ProductGalleryResponseData>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.post(`product/${this.productId}/gallery`, {
            data,
            headers,
          }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  reorder(order: number[]): Promise<ErrorAble<ProductGalleryResponseData>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.post(`product/${this.productId}/gallery/order`, {
            data: { order },
          }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  deleteImage(data: ProductGalleryRequest): Promise<ErrorAble<unknown>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await this.client.delete(
            `product/${this.productId}/gallery/${data.image_id}`,
          ),
        )
      } catch (error) {
        reject(error)
      }
    })
  }
}
